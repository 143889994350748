import { type Types, types } from '../types';
import { photoCodes, sides } from '../../pages/products/constants';
import { type PackageType, type ProductGroupData } from '../../api-generated';

import {
  type Language,
  type Name,
  type PageState,
  type TranslatedString,
} from '.';

type ProductData = {
  id: string;
  gtin: string;
  tnved: string;
  product_name: Name;
  inn: string;
  participant_name: Name;
  pg: TranslatedString;
  product_category: TranslatedString;
  package_type: TranslatedString;
  languages_complete: Record<Language, boolean>;
  required_languages: Language[];
  status_change_message: string;
  updated_at: number;
};

type ProductSource = 'RMT' | 'NC';
type ProductScope = 'LOCAL' | 'GLOBAL' | 'TECHNICAL' | 'ANY';
export type ProductStatus = 'ON_MODERATION' | 'PUBLISHED' | 'BLOCKED' | 'DRAFT';

type ProductSystemLocalizedAttributeType =
  | 'STRING_LOCALIZED'
  | 'LOCALIZED_LIST';

export type ProductSystemAttributeType =
  | ProductSystemLocalizedAttributeType
  | 'INNER_GTIN'
  | 'BOOLEAN'
  | 'ARRAY'
  | 'STRING'
  | 'INTEGER'
  | 'ENUM'
  | 'DOUBLE'
  | 'DATE'
  | 'FILE_LIST'
  | 'ENUM_LIST';

type ProductSystemAttributeScope = 'COMMON' | 'EXTRA' | 'SYSTEM';
type UnitsValues = {
  min: number;
  max: number;
};
export type ProductAttributeConstraints = {
  min: string;
  max: string;
  regexp: string;
  reference: string;
  units: Record<string, UnitsValues> | {};
};

type ProductUnit = {
  code: string;
  name: Name;
  product_group: string;
};

export type ProductAttribute = {
  code: string;
  name: Name;
  type: ProductSystemAttributeType;
  scope: ProductSystemAttributeScope;
  constraints: ProductAttributeConstraints;
  group: string;
  group_order: number;
  product_groups: Record<string, 'MANDATORY' | 'OPTIONAL'>;
  package_types: Record<string, 'MANDATORY' | 'OPTIONAL'>;
  product_scopes: Record<string, 'MANDATORY' | 'OPTIONAL'>;
  unit: ProductUnit;
  legal: 'SIGNIFICANT' | 'INSIGNIFICANT';
};

export type ProductCommonAttribute = {
  attribute: ProductAttribute;
  usageMode: 'MANDATORY' | 'OPTIONAL';
  edit_mode: Record<Language, 'EDITABLE' | 'NOT_EDITABLE'>;
  value: unknown;
};

export type ProductDetailedData = Pick<
  ProductData,
  | 'id'
  | 'gtin'
  | 'tnved'
  | 'inn'
  | 'status_change_message'
  | 'required_languages'
> & {
  product_group: Pick<ProductGroupData, 'code' | 'name'>;
  signer_full_name: string;
  source: ProductSource;
  scope: ProductScope;
  status: ProductStatus;
  category: string;
  system_attributes: ProductCommonAttribute[];
  mandatory_attributes: ProductCommonAttribute[];
  optional_attributes: ProductCommonAttribute[];
  internal_id: string;
  package_type: PackageType;
  child_id: string;
  parent_id: string;
  sign_date: string;
};

type ProductsState = PageState<ProductData> & {
  copy: unknown;
  item: ProductDetailedData;
  canCloseModalWindow: boolean;
  photo: { name: string; side: string; src: string }[];
  docs: {
    fileName: string;
    hashType: string;
    signature: string;
    src: string;
    description: string | null;
  }[];
  isLoadingPhoto: boolean;
  moderationItem: unknown;
  moderationBaseInfoItem: unknown;
  moderationPhoto: unknown;
  moderationDoc: unknown;
  moderationComments: unknown;
  openGallery: boolean;
  signData: unknown;
  certOptions: unknown[];
  photoAngleOptions: unknown[];
  photoConstraints: unknown;
};

type ProductsActionType = Types[keyof Pick<
  Types,
  | 'CLEAR_PRODUCT'
  | 'SET_PRODUCT_FIRST_STEP_TEMPLATE'
  | 'SET_PRODUCT'
  | 'SET_COPY_FOR_EDIT'
  | 'SET_PHOTO'
  | 'SET_DOCS'
  | 'GET_PHOTO'
  | 'SET_MODERATION_ITEM'
  | 'SET_MODERATION_PHOTO'
  | 'SET_MODERATION_DOC'
  | 'SET_MODERATION_ITEM_COMMENT'
  | 'SET_SIGN_DATA'
  | 'SET_CERTS'
  | 'DELETE_MODERATION_ITEM_COMMENT'
  | 'TOGGLE_GALLERY'
  | 'SET_MODERATION_BASE_INFO_ITEM'
>];

type ProductsAction = {
  type: ProductsActionType;
  payload: unknown;
};

const initialState: ProductsState = {
  item: {} as ProductDetailedData,
  copy: {},
  canCloseModalWindow: false,
  photo: [],
  docs: [],
  isLoadingPhoto: false,
  moderationItem: null,
  moderationBaseInfoItem: null,
  moderationPhoto: null,
  moderationDoc: null,
  moderationComments: {},
  openGallery: false,
  signData: null,
  certOptions: [],
  photoAngleOptions: [],
  photoConstraints: null,
};

const parseAttr = (attr) => {
  return attr.reduce((obj, item) => {
    const name = 'code_' + item.attribute.code;
    obj[name] = item.value;
    return obj;
  }, {});
};

const parseAttrUnit = (attr) => {
  return attr.reduce((obj, item) => {
    const name = `unit_${item.attribute.code}`;
    if (item.attribute.unit) {
      obj[name] = item.attribute.unit;
      return obj;
    }
    if (Object.keys(item.attribute.constraints.units).length === 1) {
      obj[name] = Object.keys(item.attribute.constraints.units)[0];
      return obj;
    }
    if (
      !item.attribute.unit &&
      Object.keys(item.attribute.constraints.units).length > 1
    ) {
      obj[name] = null;
      return obj;
    }
    return obj;
  }, {});
};

const getLanguageComplete = (list) => {
  const find = list.find(
    (item) => item?.attribute?.code === 'languages_complete'
  );
  return find
    ? {
        languages_complete: find.value,
      }
    : {};
};

export const getPhotoLink = (name) => {
  const domain = window.location.origin;
  return name ? `${domain}/photo/${name}` : null;
};

export const fetchPhotoWithHeaders = async (name) => {
  const url = getPhotoLink(name);

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'omit',
  });

  if (!response.ok) {
    throw new Error('Failed to fetch photo');
  }

  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

const parsePhotoList = (attr) => {
  return attr.reduce((photos, item) => {
    const name = item.attribute.code.toLowerCase();
    if (photoCodes.includes(name) && item.value) {
      item.value.files.forEach((el) => {
        photos.push({
          ...el,
          src: getPhotoLink(el?.fileName),
          side: name,
          editMode: item?.edit_mode,
        });
      });
    }
    return photos;
  }, []);
};

const parseDocList = (attr) => {
  const docs = attr.find((el) => el.attribute.code === 'technical_photo');
  const docList = [];
  if (docs) {
    const max = docs?.attribute?.constraints?.max;
    for (let i = 0; i < max; i++) {
      if (docs?.value?.files[i]) {
        docList.push({
          ...docs.value.files[i],
          id: `id_${Math.random()}`,
          editMode: docs?.edit_mode,
        });
      } else {
        docList.push({
          description: '',
          fileName: '',
          hashType: '',
          signature: '',
          id: `id_${Math.random()}`,
        });
      }
    }
  }
  return docList;
};

const getConstrains = (attr) => {
  const codesList = [...photoCodes, 'technical_photo'];
  return attr.reduce(
    (obj, item) => {
      const name = item.attribute.code.toLowerCase();
      if (codesList.includes(name)) {
        obj[name] = {
          min: Number(item.attribute.constraints.min),
          max: Number(item.attribute.constraints.max),
        };
        if (name !== 'technical_photo') {
          obj.totalMax += Number(item.attribute.constraints.max);
        }
      }
      return obj;
    },
    {
      totalMax: 0,
    }
  );
};

const getPhotoConstrains = (mAttr, oAttr) => {
  const mandatoryAttributes = getConstrains(mAttr);
  const optionalAttributes = getConstrains(oAttr);

  return {
    ...mandatoryAttributes,
    ...optionalAttributes,
    totalMax: mandatoryAttributes.totalMax + optionalAttributes.totalMax,
    mandatory_attributes: Object.keys(mandatoryAttributes).filter(
      (el) => el !== 'totalMax'
    ),
    optional_attributes: Object.keys(optionalAttributes).filter(
      (el) => el !== 'totalMax'
    ),
  };
};

const parseItemData = (data: ProductDetailedData) => ({
  ...data,
  ...parseAttr(data.mandatory_attributes),
  ...parseAttr(data.optional_attributes),
  ...parseAttrUnit(data.optional_attributes),
  ...parseAttrUnit(data.mandatory_attributes),
  ...getLanguageComplete(data.system_attributes),
  photoList: parsePhotoList([
    ...data.mandatory_attributes,
    ...data.optional_attributes,
  ]),
  docList: parseDocList([
    ...data.mandatory_attributes,
    ...data.optional_attributes,
  ]),
});

const getPhotoAngleOptions = (data) => {
  const options = [
    ...data.mandatory_attributes,
    ...data.optional_attributes,
  ].reduce((filtered, el) => {
    const elem = el?.attribute?.code;
    if (elem && photoCodes.includes(elem)) {
      filtered.push({
        label: sides[elem],
        value: elem,
      });
    }
    return filtered;
  }, []);
  return options || [];
};

export const reducer = (
  state: ProductsState = initialState,
  action: ProductsAction
) => {
  switch (action.type) {
    case types.CLEAR_PRODUCT:
      return {
        ...initialState,
      };
    case types.SET_PRODUCT_FIRST_STEP_TEMPLATE:
      return {
        ...state,
        item: {
          system_attributes: action.payload,
        },
      };
    case types.SET_PRODUCT: {
      const item = parseItemData(action.payload as ProductDetailedData);
      return {
        ...state,
        item,
        photoAngleOptions: getPhotoAngleOptions(item),
        photoConstraints: getPhotoConstrains(
          item.mandatory_attributes,
          item.optional_attributes
        ),
      };
    }
    case types.SET_COPY_FOR_EDIT:
      return {
        ...state,
        copy: parseItemData(action.payload),
      };
    case types.SET_PHOTO:
      return {
        ...state,
        photo: action.payload,
        isLoadingPhoto: false,
      };
    case types.SET_DOCS:
      return {
        ...state,
        docs: action.payload,
      };
    case types.GET_PHOTO:
      return {
        ...state,
        isLoadingPhoto: true,
      };
    case types.SET_MODERATION_ITEM:
      return {
        ...state,
        moderationItem: action.payload,
      };
    case types.SET_MODERATION_BASE_INFO_ITEM:
      return {
        ...state,
        moderationBaseInfoItem: action.payload,
      };
    case types.SET_MODERATION_PHOTO:
      return {
        ...state,
        moderationPhoto: action.payload,
      };
    case types.SET_MODERATION_DOC:
      return {
        ...state,
        moderationDoc: action.payload,
      };
    case types.SET_MODERATION_ITEM_COMMENT: {
      let newModerationComments = { ...state.moderationComments };
      Object.keys(action.payload).forEach((key) => {
        if (newModerationComments[key]) {
          newModerationComments[key] = {
            ...newModerationComments[key],
            reason: {
              ...newModerationComments[key].reason,
              ...action.payload[key].reason,
            },
            customComment: {
              ...newModerationComments[key].customComment,
              ...action.payload[key].customComment,
            },
            typicalComment: {
              ...newModerationComments[key].typicalComment,
              ...action.payload[key].typicalComment,
            },
          };
        } else {
          newModerationComments = {
            ...newModerationComments,
            ...action.payload,
          };
        }
      });
      return {
        ...state,
        moderationComments: newModerationComments,
      };
    }
    case types.SET_SIGN_DATA:
      return {
        ...state,
        signData: action.payload,
      };
    case types.SET_CERTS:
      return {
        ...state,
        certOptions: action.payload,
      };
    case types.DELETE_MODERATION_ITEM_COMMENT: {
      const comments = {
        ...state.moderationComments,
      };
      delete comments[action.payload];
      return {
        ...state,
        moderationComments: {
          ...comments,
        },
      };
    }
    case types.TOGGLE_GALLERY:
      return {
        ...state,
        openGallery: action.payload,
      };
    default:
      return state;
  }
};
