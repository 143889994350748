export type Types = typeof types;

export type ActionType = Types[keyof Types];

export const types = {
  SET_IS_LOADING: '[SAGS/SETTINGS]/SET_IS_LOADING',
  SET_COMMON_SETTINGS: '[SAGS/SETTINGS]/SET_COMMON_SETTINGS',
  SET_LANGUAGE: '[SAGS/SETTINGS]/SET_LANGUAGE',

  DOWNLOAD_FILE: '[SAGS/FILES]/DOWNLOAD_FILE',
  UPLOAD_FILE: '[SAGS/FILES]/UPLOAD_FILE',

  LOGIN: '[SAGS/AUTH]/LOGIN',
  LOGOUT: '[SAGS/AUTH]/LOGOUT',
  SET_AUTH_DATA: '[SAGS/AUTH]/SET_AUTH_DATA',
  SET_USER_DATA: '[SAGS/AUTH]/SET_USER_DATA',
  CLEAR_AUTH_DATA: '[SAGS/AUTH]/CLEAR_AUTH_DATA',
  RECONNECT_AUTH_DATA: '[SAGS/AUTH]/RECONNECT_AUTH_DATA',
  LOGOUT_BY_BLOCK: '[SAGS/AUTH]/LOGOUT_BY_BLOCK',
  CHECK_AUTH_DATA: '[SAGS/AUTH]/CHECK_AUTH_DATA',
  GET_CURRENT_USER: '[SAGS/AUTH]/GET_CURRENT_USER',
  SET_CURRENT_USER: '[SAGS/AUTH]/SET_CURRENT_USER',
  CLEAR_CURRENT_USER: '[SAGS/AUTH]/CLEAR_CURRENT_USER',
  CHECK_EXP_DATE: '[SAGS/AUTH]/CHECK_EXP_DATE',

  TNVED_GET_LIST: '[SAGS/TNVED]/GET_LIST',
  TNVED_SET_LIST: '[SAGS/TNVED]/SET_LIST',

  CATEGORIES_GET_LIST: '[SAGS/CATEGORIES]/CATEGORIES_GET_LIST',
  TNVED_BY_CATEGORIES_GET_LIST:
    '[SAGS/CATEGORIES]/TNVED_BY_CATEGORIES_GET_LIST',

  NATIONAL_GET_LIST: '[SAGS/NATIONAL]/GET_LIST',
  NATIONAL_SET_LIST: '[SAGS/NATIONAL]/SET_LIST',

  ENUM_GET_LIST: '[SAGS/ENUM]/GET_LIST',
  ENUM_SET_LIST: '[SAGS/ENUM]/SET_LIST',

  GET_GTINS: '[SAGS/ENUM]/GET_GTINS',
  GET_TYPICAL_COMMENTS: '[SAGS/ENUM]/GET_TYPICAL_COMMENTS',
  TEMPLATE_GET: '[SAGS/TEMPLATE]/GET',
  SET_PRODUCT: '[SAGS/PRODUCT]/SET',
  CLEAR_PRODUCT: '[SAGS/PRODUCT]/CLEAR',

  SET_MODERATION_ITEM: '[SAGS/PRODUCT]/SET_MODERATION_ITEM',
  SET_MODERATION_BASE_INFO_ITEM: '[SAGS/PRODUCT]/SET_MODERATION_BASE_INFO_ITEM',
  SET_MODERATION_PHOTO: '[SAGS/PRODUCT]/SET_MODERATION_PHOTO',
  SET_MODERATION_DOC: '[SAGS/PRODUCT]/SET_MODERATION_DOC',
  SET_MODERATION_ITEM_COMMENT: '[SAGS/PRODUCT]/SET_MODERATION_ITEM_COMMENT',
  DELETE_MODERATION_ITEM_COMMENT:
    '[SAGS/PRODUCT]/DELETE_MODERATION_ITEM_COMMENT',
  TOGGLE_GALLERY: '[SAGS/PRODUCT]/TOGGLE_GALLERY',
  UPDATE_PRODUCT_DRAFT: '[SAGS/PRODUCT]/UPDATE_PRODUCT_DRAFT',
  CREATE_PRODUCT_DRAFT: '[SAGS/PRODUCT]/CREATE_PRODUCT_DRAFT',
  GET_PRODUCT_FIRST_STEP_TEMPLATE:
    '[SAGS/PRODUCT]/GET_PRODUCT_FIRST_STEP_TEMPLATE',
  SET_PRODUCT_FIRST_STEP_TEMPLATE:
    '[SAGS/PRODUCT]/SET_PRODUCT_FIRST_STEP_TEMPLATE',
  GET_PRODUCT_SAMPLE: '[SAGS/PRODUCT]/GET_PRODUCT_SAMPLE',
  SET_PRODUCT_SAMPLE_AFTER_CHANGE_PHOTO:
    '[SAGS/PRODUCT]/SET_PRODUCT_SAMPLE_AFTER_CHANGE_PHOTO',
  BLOCK_PRODUCT: '[SAGS/PRODUCT]/BLOCK_PRODUCT',
  GET_PHOTO: '[SAGS/PRODUCT]/GET_PHOTO',
  SET_PHOTO: '[SAGS/PRODUCT]/SET_PHOTO',
  SET_DOCS: '[SAGS/PRODUCT]/SET_DOCS',
  UPLOAD_FILES: '[SAGS/PRODUCT]/UPLOAD_FILES',
  GET_COPY_FOR_EDIT: '[SAGS/PRODUCT]/GET_COPY_FOR_EDIT',
  SET_COPY_FOR_EDIT: '[SAGS/PRODUCT]/SET_COPY_FOR_EDIT',
  GET_ORIGINAL_LANGS: '[SAGS/PRODUCT]/GET_ORIGINAL_LANGS',
  SET_ORIGINAL_LANGS: '[SAGS/PRODUCT]/SET_ORIGINAL_LANGS',
  SET_SIGN_DATA: '[SAGS/PRODUCT]/SET_SIGN_DATA',
  TO_MODERATE_PRODUCT: '[SAGS/PRODUCT]/TO_MODERATE_PRODUCT',
  GET_CERTS: '[SAGS/PRODUCT]/GET_CERTS',
  SET_CERTS: '[SAGS/PRODUCT]/SET_CERTS',
  GET_MODERATION_COMMENTS: '[SAGS/PRODUCT]/GET_MODERATION_COMMENTS',
  LOAD_SIGN_DATA: '[SAGS/PRODUCT]/LOAD_SIGN_DATA',

  CHANGE_TASK_STATUS: '[SAGS/GOODS_MODERATION]/CHANGE_TASK_STATUS',
  SET_TASK_STATUS_CHANGED: '[SAGS/GOODS_MODERATION]/SET_TASK_STATUS_CHANGED',
  ADD_COMMENTS: '[SAGS/GOODS_MODERATION]/ADD_COMMENTS',
  DELETE_COMMENT: '[SAGS/GOODS_MODERATION]/DELETE_COMMENT',
  GET_COMMENTS: '[SAGS/GOODS_MODERATION]/GET_COMMENTS',
  SET_COMMENTS: '[SAGS/GOODS_MODERATION]/SET_COMMENTS',
  SET_REJECTED_LANGS: '[SAGS/GOODS_MODERATION]/SET_REJECTED_LANGS',
  GET_TASK_ITEM: '[SAGS/GOODS_MODERATION]/GET_TASK_ITEM',
  SET_TASK_ITEM: '[SAGS/GOODS_MODERATION]/SET_TASK_ITEM',
  GET_REPORT: '[SAGS/GOODS_MODERATION]/GET_REPORT',
} as const;

/**
 * @deprecated
 * use named import instead
 * `import {types} from '...'`
 * */
const defaultExport = types;

// eslint-disable-next-line import/no-default-export
export default defaultExport;
