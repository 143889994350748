import {
  useDispatch,
  useSelector,
  type TypedUseSelectorHook,
} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { default as createSagaMiddleware } from 'redux-saga';
import { createBrowserHistory } from 'history';

import { api } from '../api-generated';

import { rootSaga } from './sagas';
import { reducers } from './reducers';
import { types } from './types';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    ...reducers,
    router: connectRouter(history),
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          types.CREATE_PRODUCT_DRAFT,
          types.UPDATE_PRODUCT_DRAFT,
        ],
      },
    }).concat(sagaMiddleware, routerMiddleware(history), api.middleware),
  devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

sagaMiddleware.run(rootSaga);
