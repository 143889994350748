import fp, { cloneDeep, isNil, isEmpty } from 'lodash/fp';
import { format, parseISO } from 'date-fns';

import { validators } from '@ibox/ui';

import { photoCodes } from '../constants';
import {
  ProductDetailedData,
  ProductCommonAttribute,
} from '../../../store/reducers/products';

type File = {
  filename: string;
  checksum: string;
  hashType: string;
};

type UploadedFile = Record<string, File>;

const parseValue = (
  type: ProductCommonAttribute['attribute']['type'],
  value: unknown
) => {
  switch (type) {
    case 'ARRAY': {
      return value ? (typeof value === 'string' ? [value] : value) : value;
    }
    case 'DATE':
      return value ? format(parseISO(value), 'yyyy-MM-dd') : value;
    case 'INTEGER':
      return value ? parseInt(value, 10) : value;
    case 'STRING_LOCALIZED': {
      const result = {};
      if (value) {
        Object.keys(value).forEach((key) => {
          if (value[key]) {
            result[key] = value[key];
          }
        });
      }
      return !isEmpty(result) ? result : null;
    }
    case 'DOUBLE': {
      return !isNil(value) ? parseFloat(value) : value;
    }
    case 'ENUM_LIST': {
      return value ? value.map((item) => item.value) : value;
    }
    default:
      return value;
  }
};

const getEmptyPhotos = (currentValue, newPhotoList, code) => {
  const emptyItems = [];
  const changedSideItems = currentValue?.files?.filter(
    (el: { signature: string }) =>
      newPhotoList.find(
        (item: { signature: string; side: string }) =>
          item.signature === el.signature && item.side !== code
      )
  );
  if (changedSideItems?.length) {
    changedSideItems.forEach(() => {
      emptyItems.push({
        fileName: '',
        hashType: '',
        side: code,
        signature: '',
        src: '',
      });
    });
  }
  return emptyItems;
};

export const prepareProductDraft = (template: ProductDetailedData, values) => {
  const draft = {
    id: template.id,
    ...(values.national && {
      national: values.national,
    }),
    ...(values.inner_product && {
      inner_product: values.inner_product,
    }),
    ...(values.inner_products_count && {
      inner_products_count: values.inner_products_count,
    }),
  };

  const mandatoryAttributes = {};
  const optionalAttributes = {};
  const mandatoryAttr = template.mandatory_attributes;
  const optionalAttr = template.optional_attributes;
  let attributesWithTypeFile = [];
  const emptyItems = [];

  mandatoryAttr.forEach((item) => {
    const { code, type } = item.attribute;
    const currentValue =
      values[code] !== ''
        ? values[code] === undefined
          ? null
          : values[code]?.value ?? values[code]
        : item.value;

    if (type === 'FILE_LIST') {
      if (currentValue) {
        getEmptyPhotos(currentValue, values.photoList, code).forEach((item) => {
          emptyItems.push(item);
        });
      }
      attributesWithTypeFile = [
        ...attributesWithTypeFile,
        currentValue
          ? currentValue.files
          : [
              {
                fileName: null,
                side: code,
              },
            ],
      ];
    } else {
      mandatoryAttributes[code] = parseValue(type, currentValue);
    }
  });
  optionalAttr.forEach((item) => {
    const { code, type } = item.attribute;
    const currentValue =
      values[code] !== ''
        ? values[code] === undefined || values[code]?.value === null
          ? null
          : values[code]?.value ?? values[code]
        : item.value;

    if (type === 'FILE_LIST') {
      if (currentValue) {
        getEmptyPhotos(currentValue, values.photoList, code).forEach((item) => {
          emptyItems.push(item);
        });
      }
      attributesWithTypeFile = [
        ...attributesWithTypeFile,
        currentValue
          ? currentValue.files
          : [
              {
                fileName: null,
                side: code,
              },
            ],
      ];
    } else {
      optionalAttributes[code] = parseValue(type, currentValue);
    }
  });

  const resultRaw = {
    ...draft,
    ...mandatoryAttributes,
    ...optionalAttributes,
  };

  const result = fp.pipe(fp.omit([...photoCodes, 'technical_photo', 'photo']))(
    resultRaw
  );

  return {
    ...result,
    photoList: [...values.photoList, ...emptyItems],
    docList: values.docList,
    attributesWithTypeFile: fp.flattenDeep(attributesWithTypeFile),
  };
};

export const getUnits = (formValues) =>
  Object.keys(formValues).reduce(
    (acc, key) =>
      key.startsWith('unit_')
        ? { ...acc, [key.replace('unit_', '')]: formValues[key] }
        : acc,
    {}
  );

export const getProductParameters = (data, uploadedFiles: UploadedFile) => {
  const newData = cloneDeep(data);
  const { id } = newData;

  const oldPhotos = newData.photoList.filter((el) => !el.file);
  const docs = newData.docList.reduce((acc, item) => {
    const { description, signature, externalId } = item;
    const fileName = uploadedFiles[externalId]?.filename || item?.fileName;
    const hashType =
      uploadedFiles[externalId]?.hashType || item?.hashType || 'SHA3_256';
    if (fileName) {
      acc.push({
        description,
        fileName,
        hashType,
        signature,
      });
    }
    return acc;
  }, []);
  const photoData = getAttrForPhotos(oldPhotos);
  const uploadedFileData = getAttrForNewFiles(uploadedFiles, data);
  if (docs.length) {
    newData.technical_photo = { files: docs };
  }
  delete newData.id;
  delete newData.photoList;
  delete newData.docList;
  delete newData.attributesWithTypeFile;

  return { id, newData: { ...newData, ...photoData, ...uploadedFileData } };
};

export const prepareOptions = (
  lang,
  list,
  key,
  isTnved = false,
  isPackageType = false
) => {
  return list
    ? list.map((item) => ({
        value: isPackageType ? item.code.toUpperCase() : item.code,
        label: isTnved ? `${item.code} - ${item.name[lang]}` : item[key][lang],
      }))
    : [];
};

export const getAttrForPhotos = (list) => {
  const data: Record<string, unknown> = {};
  if (list.length) {
    list.forEach((el) => {
      if (data[el.side]?.files?.length) {
        if (el.signature) {
          data[el.side].files.push({
            fileName: el.fileName,
            hashType: el.hashType,
            signature: el.signature,
          });
        }
      } else {
        if (el.signature) {
          data[el.side] = {
            files: [
              {
                fileName: el.fileName,
                hashType: el.hashType,
                signature: el.signature,
              },
            ],
          };
        } else {
          data[el.side] = null;
        }
      }
    });
  }
  return data;
};

export const getAttrForNewFiles = (uploadedFiles: UploadedFile, data) => {
  const newPhotos = data.photoList
    .filter((el) => el.file)
    .map((el) => ({
      ...el,
      fileName: uploadedFiles[el.externalId]?.filename,
      hashType: uploadedFiles[el.externalId]?.hashType,
      signature: uploadedFiles[el.externalId]?.checksum,
    }));
  return getAttrForPhotos(newPhotos);
};

export const getProductNewFiles = (data) => {
  const newData = cloneDeep(data);
  const newPhotos = newData.photoList.filter((el) => el.file);

  const newFileAttributes = newData.attributesWithTypeFile.filter(
    (el) => el.file
  );

  const newDocs = newData.docList.reduce((acc, item) => {
    if (item.file) {
      const { description, file, externalId } = item;
      acc.push({
        description,
        file,
        side: 'technical_photo',
        externalId,
      });
    }
    return acc;
  }, []);

  return [...newPhotos, ...newDocs, ...newFileAttributes];
};

export const getValidatorsWithoutRequired = (item: ProductCommonAttribute) => {
  const constraints = item.attribute.constraints;
  const min = validators.length.min;
  const max = validators.length.max;

  const validatorRaw = validators.pipe(
    constraints.max && max(+constraints.max),
    constraints.min && min(+constraints.min),
    constraints.regexp && validators.is.regexp(constraints.regexp)
  );

  const validator = (valueRaw: string) => {
    const value = valueRaw;
    return !fp.isNil(value) && value !== '' ? validatorRaw(value) : '';
  };
  return validator;
};
