/* eslint-disable */
import axios from 'axios';
import qs from 'qs';

import {
  checkContentType,
  CONTENT_TYPES,
  getContentDispositionFilename,
  isHasBody,
  isNeedVersionApi,
  saveAsFile,
} from '../helpers/http';

import Errors from './errors';
import { URL_BASE } from './constants';

type OptionsProps = {
  access_token: string;
  basic: any;
  body: any;
  content_type: string;
  headers: any;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  onDownloadProgress: any;
  onUploadProgress: any;
  path: string;
  query: any;
  responseType: any;
  token_type: any;
  url: any;
  hideError: boolean;
  withBody: boolean;
};

const VERSION_API = '';
const BASIC = {
  username: 'crpt-service',
  password: 'secret',
};

const resolve = ({
  response,
  saveAs,
  fileName,
}: {
  response: any;
  saveAs: any;
  fileName: string;
}) => {
  const isContentType = checkContentType(response);
  const response_json = isContentType(CONTENT_TYPES.json) && response.data;
  switch (response.status) {
    case 200:
    case 201:
    case 202:
      return saveAs
        ? saveAsFile(
            response,
            fileName || getContentDispositionFilename(response, saveAs)
          )
        : response_json || response;
    case 204:
      return null;
    default:
      console.warn(`Unknown response status: ${response.status}\n`, response);
      return null;
  }
};

const reject = (response) => {
  if (
    response.request.responseType === 'blob' &&
    response.data instanceof Blob
  ) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        response.data = JSON.parse(reader.result);
        resolve(Promise.reject(response));
      };
      reader.onerror = () => {
        reject(response);
      };
      reader.readAsText(response.data);
    });
  }
  switch (response.status) {
    case 401: {
      return;
    }
    case 403:
      throw new Errors.Forbidden(response);
    case 400:
      throw new Errors.BadRequest(response);
    case 500:
      throw new Errors.Backend(response);
    default:
      throw new Errors.Unknown(response, response.data);
  }
};

const options = ({
  access_token,
  basic,
  body,
  content_type = CONTENT_TYPES.json,
  headers = {},
  method = 'POST',
  onDownloadProgress,
  onUploadProgress,
  path = '',
  query = {},
  responseType,
  token_type,
  url = (() => {
    throw new Error('Url is not specified');
  })(),
  hideError,
  withBody,
}: OptionsProps) => {
  const hasBody = isHasBody(method) || withBody;
  const data =
    body &&
    hasBody &&
    (content_type === CONTENT_TYPES.json
      ? JSON.stringify(body)
      : content_type === CONTENT_TYPES.form
      ? qs.stringify(body)
      : body);
  const url_path = path ? `/${path}` : '';
  const url_query = qs.stringify(
    { ...query, ...(!hasBody && body) },
    { arrayFormat: 'repeat' }
  );
  const url_version_api = isNeedVersionApi(url) ? '' : '/v1';
  const url_base = url === '/oauth' ? '' : URL_BASE;
  const url_request = [
    url_base,
    url_version_api,
    url,
    url_path,
    url_query ? '?' : '',
    url_query,
  ].join('');

  return {
    method,
    ...(data && { data }),
    ...(basic && { auth: basic }),
    headers: {
      ...(hasBody && { 'Content-Type': content_type }),
      ...(access_token && { Authorization: `Bearer ${access_token}` }),
      ...headers,
    },
    responseType,
    onUploadProgress,
    onDownloadProgress,
    url: url_request,
    hideError,
    timeout: 900000,
  };
};

const request = ({ parent, reconnect = reject, saveAs, fileName, ...props }) =>
  axios(options(props)).then(
    (response) => resolve({ response, saveAs, fileName }),
    parent ? reject : ({ response }) => reject(response)
  );

const connect = (props) => {
  const { reconnect } = props;
  const { access_token, refresh_token, token_type } = props.auth || {};
  const method =
    ({ name, ...opts }) =>
    (args) =>
      request({
        access_token,
        token_type,
        ...opts,
        ...args,
        reconnect,
        method: name,
      });

  const get = method({ name: 'GET' });
  const post = method({ name: 'POST' });
  const put = method({ name: 'PUT' });
  const patch = method({ name: 'PATCH', content_type: CONTENT_TYPES.patch });
  const remove = method({ name: 'DELETE' });

  const crud = (url: string) => ({
    create: (body, query) => post({ url, body, query }),
    item: (id: string) => get({ url, path: id }),
    list: (query = {}) => get({ url, query }),
    patch: ({ id, body }) => patch({ url, path: id, body }),
    update: ({ id, body }) => put({ url, path: id, body }),
    download: (id, onDownloadProgress) =>
      get({
        url,
        path: id,
        responseType: 'blob',
        onDownloadProgress,
        saveAs: `download-${id}.file`,
      }),
    upload: (files, onUploadProgress) =>
      window.Promise.all(
        files.map((file) => {
          const body = new window.FormData();
          body.append('file', file);
          return post({
            url,
            content_type: CONTENT_TYPES.formdata,
            onUploadProgress,
            body,
          });
        })
      ),
  });

  const auth = (url: string) => ({
    check: (token) =>
      post({
        url,
        basic: BASIC,
        path: 'check_token',
        query: { token },
      }),
    reconnect: (token = null) =>
      post({
        url,
        parent: true,
        path: 'oauth2',
        basic: BASIC,
        content_type: CONTENT_TYPES.form,
        body: {
          grant_type: 'refresh_token',
          refresh_token: token ? token : refresh_token,
        },
      }),
    signin: ({ username, password }: { username: string; password: string }) =>
      post({
        url,
        basic: BASIC,
        path: 'oauth2',
        content_type: CONTENT_TYPES.form,
        body: { grant_type: 'password', username, password },
      }),
    signout: () => remove({ url, path: 'token' }),
  });

  const file = (url: string) => ({
    uploadFile: (files: File[], onUploadProgress) =>
      window.Promise.all(
        files.map((file: File) => {
          const body = new window.FormData();
          body.append('file', file);
          return post({
            url,
            content_type: CONTENT_TYPES.default,
            onUploadProgress,
            body,
          });
        })
      ),
    download: (query: string, fileName: string) =>
      get({
        url,
        path: 'csv',
        query,
        content_type: CONTENT_TYPES.csv,
        responseType: 'blob',
        saveAs: 'mark-codes.csv',
        fileName,
      }),
  });

  const draft = (url: string) => ({
    ...crud(url),
    updateDraft: (id, body) => put({ url, path: id, body }),
    updateNCDraft: (id, body) =>
      put({ url: `/operator${url}`, path: id, body }),
    createDraft: (body) => post({ url, body }),
    getCopyForEdit: (id) => get({ url, path: `${id}/edit` }),
    getParticipantProducts: (query) => get({ url, query }),
    toModerate: (id, body) =>
      patch({ url, path: `${id}/status/moderate`, body }),
    toDraft: (id, body) => patch({ url, path: `${id}/status/draft`, body }),
    publishDraft: (id) => patch({ url, path: `${id}/status/publish` }),
    blockDraft: (id) => patch({ url, path: `${id}/status/block` }),
    uploadFile: (file, onUploadProgress, id) => {
      const body = new window.FormData();
      body.append('file', file.file);
      body.append('attributeName', file.side);
      body.append('description', file?.description || '');
      return post({
        url,
        path: `${id}/file`,
        content_type: CONTENT_TYPES.default,
        onUploadProgress,
        body,
      });
    },
    uploadFiles: (body, onUploadProgress) => {
      return post({
        url,
        path: 'files',
        content_type: CONTENT_TYPES.formdata,
        onUploadProgress,
        body,
      });
    },
    getPhoto: ({ id, query }) =>
      get({
        url,
        path: `${id}/photo`,
        query,
        responseType: 'arraybuffer',
      }),
    removePhoto: ({ id, query }) =>
      remove({ url, path: `${id}/photo/remove`, query }),
    removeDraft: (id) => remove({ url, path: `${id}` }),
    getTemplate: (query) => get({ url, path: 'template', query }),
    getSingData: (id) => get({ url, path: `${id}/signdata` }),
    getComments: (id) => get({ url, path: `${id}/comments` }),
    nameSuggest: (query) => get({ url: '/product/suggest-name', query }),
    tnvedSuggest: (code) =>
      get({ url: '/classifier/tnved/suggest-code', query: { code } }),
  });

  const productGroups = (url: string) => ({
    ...crud(url),
    getCodes: (query) => get({ url: '/classifier/tnved', query }),
    getPackageTypes: (query) => get({ url: '/enum/package_type', query }),
    attributes: (query) => get({ url: '/attribute', query }),
    getListByProductGroup: (code) => get({ url, path: `${code}/category` }),
    getPackagesProductGroup: (code) => get({ url, path: `${code}/packages` }),
    nameSuggest: ({ name, language }) =>
      get({ url: '/pg/suggest-name', query: { name, language } }),
    getCategories: ({ code, query }) =>
      get({ url: `/pg/${code}/category`, query }),
  });

  const admin = (url: string) => ({
    ...crud(url),
    getDraftList: (query) => get({ url, path: 'draft/', query }),
    getDraftById: (id) => get({ url, path: 'draft/' + id }),
    publishDraft: (id) => patch({ url, path: `draft/${id}/status/publish` }),
    blockDraft: (id) =>
      path({ url, path: `draft/${id}/status`, body: 'BLOCKED' }),
  });

  const attributes = (url) => ({
    extraList: (query) => get({ url, path: 'extra', query }),
    firstStepTemplate: () => get({ url, path: 'identifying' }),
  });

  const enumeration = (url) => ({
    getEnum: (name, sortLanguage) =>
      get({ url, path: name, query: { sortLanguage } }),
  });

  const tasks = (url: string) => ({
    ...crud(url),
    changeStatus: ({ id, status }) =>
      put({ url, path: `${id}/status/${status}` }),
    addComments: ({ id, body }) => put({ url, path: `${id}/comments`, body }),
    getComments: (id) => get({ url, path: `${id}/comments` }),
    deleteComment: ({ taskId, commentId }) =>
      remove({ url, path: `${taskId}/comments/${commentId}` }),
    assignTasks: (id, body) => patch({ url, path: `assignee/${id}`, body }),
    userSuggest: (fullName) =>
      get({
        url: '/moderator/user/suggest',
        query: { fullName },
      }),
    getReport: (query) =>
      get({
        url,
        path: 'report',
        query,
        content_type: CONTENT_TYPES.xlsx,
        responseType: 'blob',
        saveAs: 'operations.xlsx',
      }),
  });

  const prefixes = (url: string) => ({
    list: (query) => get({ url, query }),
    remove: (prefix) => remove({ url, path: prefix }),
    block: (prefix, query, body) =>
      patch({ url, path: `${prefix}/block`, query, body }),
    create: (body) => put({ url, body }),
    prefixesSuggest: (prefix) =>
      get({ url: '/prefixes/suggest', query: { prefix } }),
  });

  const participants = (url) => ({
    ...crud(url),
    list: (body, query) => post({ url, body, query }),
    block: (inn) => patch({ url, path: `${inn}/block` }),
    changeNames: (inn, body) => patch({ url, path: `${inn}/name`, body }),
    nameSuggest: ({ name, language }) =>
      get({
        url,
        path: 'suggest-name',
        query: { name, language },
      }),
    innSuggest: (inn) =>
      get({
        url,
        path: 'suggest-inn',
        query: { inn },
      }),
  });

  const category = (url: string) => ({
    ...crud(url),
    getTnvedByCategory: (code) => get({ url, path: `${code}/tnved` }),
    nameSuggest: ({ name, language }) =>
      get({ url: '/category/suggest-name', query: { name, language } }),
    getCurrentCategory: ({ code }) => get({ url: `/category/${code}` }),
  });

  const moderator = (url) => ({
    ...crud(url),
    suggest: (query) => get({ url, path: `user/suggest`, query }),
  });

  return {
    get,
    remove,
    post,
    put,
    patch,
    auth: auth('/auth'),
    participants: participants('/admin/organizations'),
    tnved: crud('/classifier/tnved/published'),
    national: crud('/classifier/national'),
    enumeration: enumeration('/enum'),
    product: draft('/product'),
    admin: admin('/admin'),
    tasks: tasks('/task'),
    gtinRegistry: crud('/gtin-registry/'),
    attributes: attributes('/attribute'),
    productGroups: productGroups('/pg'),
    prefixes: prefixes('/prefixes'),
    typicalComments: crud('/typical_comments'),
    category: category('/category'),
    moderator: moderator('/moderator'),
  };
};

export default connect;
